
import Vue from 'vue';
import errorHandler from '@/helpers/errorHandler';
import {
  V1EntitiesAnalyticsFeedbackQuestionsTempGroup,
  V1EntitiesAnalyticsFeedbackQuestionsTempItem,
  V1EntitiesAnalyticsTemplatesSettingsZone,
} from '@/services/api/tsxass';
import { HeatMapColumn } from '@/components/analytics/charts/HeatMap/types';
import { tsxassApi } from '@/services/api';
import CompetenciesHeatMap from './common/CompetenciesHeatMap.vue';

export default Vue.extend({
  name: 'ExtraQuestionsHeatMap',

  components: {
    CompetenciesHeatMap,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      chartData: {
        groups: [] as V1EntitiesAnalyticsFeedbackQuestionsTempGroup[],
        items: [] as V1EntitiesAnalyticsFeedbackQuestionsTempItem[],
        settings: {
          minValue: 1,
          maxValue: 5,
        },
        zones: [] as V1EntitiesAnalyticsTemplatesSettingsZone[],
      },
      loading: false,
    };
  },

  computed: {
    fixedColumns(): HeatMapColumn[] {
      return [
        {
          key: 'name',
          title: this.$t('analytics.additionalQuestions.competency') as string,
          isFixed: true,
          width: 350,
          cellClass: 'tt-light-mono-100--text',
        },
        {
          key: 'experts',
          title: '',
          hint: this.$t('analytics.additionalQuestions.expertsCountHint') as string,
          isFixed: true,
          width: 50,
          align: 'center',
        },
        {
          key: 'declined',
          title: '',
          hint: this.$t('analytics.additionalQuestions.rejectionPercentHint') as string,
          isFixed: true,
          width: 50,
          align: 'center',
        },
        {
          key: 'average',
          title: this.$t('analytics.additionalQuestions.average') as string,
          isFixed: true,
          width: 75,
        },
      ];
    },
  },

  mounted() {
    this.loadChartData();
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackExtraQuestionsTemp(
          this.surveyId,
          this.userId || undefined,
        );
        this.chartData = {
          // @ts-ignore
          groups: data.groups || [],
          items: data.items || [],
          settings: data.settings || { minValue: 1, maxValue: 5 },
          zones: data.analyticsTemplateSettings.zones,
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
