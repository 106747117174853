
import { defineComponent, PropType } from 'vue';

import { V1EntitiesAnalyticsTemplatesSettingsZone } from '@/services/api/tsxass';

export default defineComponent({
  name: 'GradeValueSmile',

  props: {
    value: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      default: 13,
    },
    zones: {
      type: Array as PropType<V1EntitiesAnalyticsTemplatesSettingsZone[]>,
      default: () => [],
    },
  },

  computed: {
    zone() {
      return this.zones.find((z) => z.value >= this.value) || this.zones[this.zones.length - 1];
    },
  },
});
