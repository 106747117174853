
import Vue, { PropType } from 'vue';
import {
  ChartConfiguration, Chart, registerables,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import JohariWindowQuadrantsPlugin from '@/utils/charts/plugins/JohariWindowQuadrantsPlugin';
import JohariWindowGridlinesPlugin from '@/utils/charts/plugins/JohariWindowGridlinesPlugin';
import AxisArrowsPlugin from '@/utils/charts/plugins/AxisArrowsPlugin';
import TTLoader from '@/components/ui/TTLoader.vue';
import debounce from 'lodash/debounce';
import {
  JohariChartDataSetData,
  JohariPoint,
  JohariColorSettings,
} from './types';

const DEFAULT_COLOR_SETTINGS: JohariColorSettings = {
  topLeft: {
    background: '#FFF6DC',
    point: '#FFC700',
  },
  topRight: {
    background: '#D4F8E7',
    point: '#00D358',
  },
  bottomLeft: {
    background: '#FFE2E2',
    point: '#FF2B3E',
  },
  bottomRight: {
    background: '#FFE2E2',
    point: '#FF2B3E',
  },
};

Chart.register(...registerables);

/**
 * Компонент графика Окно Джохари.
 * Представляет из себя график с четырьмя квадрантами и точками внутри них.
 * Квадранты равны по площади и размерам.
 * Но первые два (слева) могут содержать в себе другое количество целых значений шкалы, чем вторые два (справа).
 * Например: первым квадрантам соответствуют значения x от 1 до 3, а вторым - от 4 до 5, при этом визуально
 * квадранты остаются одинакового размера, изменяется шкала x -
 * длины отрезков между значениями становятся неравномерными). Аналогично для шкалы y.
 * ChartJS умеет выводить "тики" только с одинаковым расстоянием между друг другом,
 * поэтому реализовано следующее решение:
 * - пользователь передает в пропсах границы квадрантов (min и max), средние значения и массив значений точек
 * - реальные значения точек мапятся на доступный диапазон из квадранта.
 */
export default Vue.extend({
  name: 'JohariWindow',

  components: {
    TTLoader,
  },

  props: {
    colorSettings: {
      type: [Object, null] as PropType<JohariColorSettings>,
      default: () => ({ ...DEFAULT_COLOR_SETTINGS }),
    },
    points: {
      type: Array as PropType<JohariPoint[]>,
      default: () => [],
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 5,
    },
    yScaleTitle: {
      type: String,
      default: '',
    },
    xScaleTitle: {
      type: String,
      default: '',
    },
    middleValueX: {
      type: Number,
      default: 7,
    },
    middleValueY: {
      type: Number,
      default: 7,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chart: null as Chart<'scatter', JohariChartDataSetData[]> | null,
      // Размер единичного отрезка в пикселях для каждой оси
      chartBasisSizeInPixels: {
        x: 0,
        y: 0,
      },
    };
  },

  computed: {
    preparedDataSets(): any {
      const getQuadrantPoints = (minX: number, maxX: number, minY: number, maxY: number) => {
        const filteredPoints = this.points.filter((point) => point.x - this.min >= minX
          && point.x - this.min <= maxX
          && point.y - this.min >= minY
          && point.y - this.min <= maxY);
        return filteredPoints.map((point) => ({
          ...point,
          x: this.getPointChartCoord(point.x, minX, maxX, this.chartBasisSizeInPixels.x),
          y: this.getPointChartCoord(point.y, minY, maxY, this.chartBasisSizeInPixels.y),
        }));
      };

      return [
        {
          label: 'topLeft',
          data: getQuadrantPoints(0, this.middleValueX - this.min,
            this.middleValueY - this.min + 0.01, this.max - this.min),
          borderColor: this.colorSettings.topLeft.point,
          backgroundColor: this.colorSettings.topLeft.point,
        },
        {
          label: 'bottomLeft',
          data: getQuadrantPoints(0, this.middleValueX - this.min,
            0, this.middleValueY - this.min),
          borderColor: this.colorSettings.bottomLeft.point,
          backgroundColor: this.colorSettings.bottomLeft.point,
        },
        {
          label: 'topRight',
          data: getQuadrantPoints(this.middleValueX - this.min + 0.01, this.max - this.min,
            this.middleValueY - this.min + 0.01, this.max - this.min),
          borderColor: this.colorSettings.topRight.point,
          backgroundColor: this.colorSettings.topRight.point,
        },
        {
          label: 'bottomRight',
          data: getQuadrantPoints(this.middleValueX - this.min + 0.01, this.max - this.min,
            0, this.middleValueY - this.min),
          borderColor: this.colorSettings.bottomRight.point,
          backgroundColor: this.colorSettings.bottomRight.point,
        },
      ];
    },

    chartSettings(): ChartConfiguration<'scatter', JohariChartDataSetData[]> {
      const bounds = {
        minX: 0,
        maxX: this.max - this.min,
        minY: 0,
        maxY: this.max - this.min,
      };

      return {
        type: 'scatter',
        data: {
          datasets: this.preparedDataSets,
        },
        options: {
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              position: 'left',
              suggestedMin: 0,
              suggestedMax: this.max - this.min,
              min: 0,
              max: this.max - this.min,
              title: {
                display: true,
                text: this.yScaleTitle,
                padding: {
                  bottom: 12,
                },
              },
              ticks: {
                display: false,
                sampleSize: 0,
              },
              grid: {
                drawOnChartArea: false,
                tickLength: 0,
              },
            },
            x: {
              suggestedMin: 0,
              suggestedMax: this.max - this.min,
              min: 0,
              max: this.max - this.min,
              grid: {
                drawOnChartArea: false,
                tickLength: 0,
              },
              title: {
                display: true,
                text: this.xScaleTitle,
                padding: {
                  top: 12,
                },
              },
              ticks: {
                display: false,
              },
            },
          },
          elements: {
            point: {
              radius: 12,
              pointStyle: 'rectRounded',
              hoverRadius: 16,
            },
            line: {
              borderWidth: 3,
            },
          },
          plugins: {
            // @ts-ignore
            johariQuadrants: {
              bounds,
              margin: 8,
              colors: {
                topLeft: this.colorSettings.topLeft.background,
                topRight: this.colorSettings.topRight.background,
                bottomRight: this.colorSettings.bottomRight.background,
                bottomLeft: this.colorSettings.bottomLeft.background,
              },
            },
            johariGridlines: {
              bounds,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: 'white',
              font: {
                family: 'Roboto',
                size: 12,
                weight: 500,
                lineHeight: 1,
              },
              formatter: (value) => value.title,
              offset: 2,
              padding: {
                top: 8,
                right: 7,
                bottom: 4,
                left: 7,
              },
              listeners: {
                click: (context) => {
                  this.$emit('point-click', context.dataset.data[context.dataIndex]);
                },
              },
            },
          },
          onResize: () => {
            this.updateChartBasisSize();
          },
        },
        plugins: [
          ChartDataLabels,
          JohariWindowQuadrantsPlugin,
          JohariWindowGridlinesPlugin,
          AxisArrowsPlugin,
        ],
      };
    },
  },

  watch: {
    chartSettings: {
      handler(val) {
        if (!this.chart) {
          return;
        }

        this.chart.data = val.data;
        this.chart.options = val.options;

        this.chart.update();
      },
    },
  },

  created() {
    this.updateChartBasisSize = debounce(this.updateChartBasisSize, 300);
  },

  mounted() {
    this.chart = new Chart(this.$refs.canvasEl as HTMLCanvasElement, this.chartSettings);
    this.updateChartBasisSize();
  },

  beforeDestroy() {
    this.chart?.destroy();
  },

  methods: {

    getScaleOfMinMax(_min: number, _max: number, _padding: number) {
      const mid = (this.max - this.min) / 2 - _padding * 48;
      return ((_max - _min) / mid);
    },

    getPointChartCoord(_value: number, _min: number, _max: number, _padding: number) {
      const scale = this.getScaleOfMinMax(_min, _max, _padding);
      let prevScale = this.getScaleOfMinMax(0, _min, _padding);
      const borderPadding = _padding * 24;
      let middlePadding = _padding * 48;
      if (_min === 0) {
        prevScale = scale;
        middlePadding = 0;
      }
      const result = borderPadding + _min / prevScale + middlePadding + (_value - _min - this.min) / scale;
      return result;
    },

    updateChartBasisSize() {
      const calcForAxis = (scale: string) => {
        if (!this.chart?.scales[scale]) {
          return 0;
        }
        const a = this.chart?.scales[scale].getValueForPixel(1) || 0;
        const b = this.chart?.scales[scale].getValueForPixel(0) || 0;

        return Math.abs(b - a);
      };

      this.chartBasisSizeInPixels = {
        x: calcForAxis('x'),
        y: calcForAxis('y'),
      };
    },
  },
});
