
import Vue from 'vue';
import errorHandler from '@/helpers/errorHandler';
import { HeatMapColumn } from '@/components/analytics/charts/HeatMap/types';
import {
  V1EntitiesAnalyticsFeedbackQuestionsTempGroup,
  V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem,
  V1EntitiesAnalyticsFeedbackQuestionsTempItem,
  V1EntitiesAnalyticsTemplatesSettingsZone,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import CompetenciesCommentsDialog from '@/components/analytics/widgets/common/CompetenciesCommentsDialog.vue';
import CompetenciesHeatMap from './common/CompetenciesHeatMap.vue';

type TempItem = V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem
  | V1EntitiesAnalyticsFeedbackQuestionsTempGroup
  | V1EntitiesAnalyticsFeedbackQuestionsTempItem;

export default Vue.extend({
  name: 'BaseCompetenciesHeatMap',

  components: {
    CompetenciesCommentsDialog,
    CompetenciesHeatMap,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      chartData: {
        groups: [] as V1EntitiesAnalyticsFeedbackQuestionsTempGroup[],
        items: [] as V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem[],
        settings: {
          minValue: 1,
          maxValue: 5,
          displayBenches: false,
        },
        zones: [] as V1EntitiesAnalyticsTemplatesSettingsZone[],
      },
      loading: false,
      isShowCommentsDialog: false,
      commentsDialogData: {
        questionId: '',
        userId: '',
        groupName: '',
        questionName: '',
      },
    };
  },

  computed: {
    columns(): HeatMapColumn[] {
      return [
        {
          key: 'name',
          title: this.$t('analytics.additionalQuestions.competency') as string,
          isFixed: true,
          width: 310,
          cellClass: 'tt-light-mono-100--text',
        },
        {
          key: 'experts',
          title: '',
          hint: this.$t('analytics.additionalQuestions.expertsCountHint') as string,
          isFixed: true,
          width: 50,
          align: 'center',
        },
        {
          key: 'declined',
          title: '',
          hint: this.$t('analytics.additionalQuestions.rejectionPercentHint') as string,
          isFixed: true,
          width: 50,
          align: 'center',
        },
        {
          key: 'comments',
          title: this.$t('analytics.baseCompetencies.heatMap.commentsCount') as string,
          isFixed: true,
          width: 40,
          align: 'center',
        },
        {
          key: 'average',
          title: this.$t('analytics.baseCompetencies.heatMap.average') as string,
          isFixed: true,
          width: 60,
        },
        {
          disabled: !this.chartData.settings?.displayBenches,
          key: 'bench',
          title: this.$t('analytics.baseCompetencies.heatMap.bench') as string,
          isFixed: true,
          width: 50,
          format: (val: number) => val.toFixed(1),
        },
      ].filter((c) => !c.disabled);
    },
  },

  mounted() {
    this.loadChartData();
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackGeneralQuestionsTemp(
          this.surveyId,
          this.userId || undefined,
        );
        this.chartData = {
          groups: data.groups || [],
          items: data.items || [],
          settings: data.settings || {
            minValue: 1,
            maxValue: 5,
            displayBenches: data.settings?.displayBenches,
          },
          zones: data.analyticsTemplateSettings.zones,
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    getCommentText(value: number | null): string {
      if (value === null) {
        return '—';
      }
      return String(value);
    },
    getCommentHint(value: number | null): string {
      if (value > 0) {
        return this.$t('analytics.baseCompetencies.heatMap.commentsCount') as string;
      }
      if (value === 0) {
        return this.$t('analytics.baseCompetencies.heatMap.noComments') as string;
      }
      return this.$t('analytics.baseCompetencies.heatMap.commentsTurnedOff') as string;
    },
    onCommentClick(isParentRow: boolean, item: V1EntitiesAnalyticsFeedbackGeneralQuestionsTopItem, value: number) {
      if (isParentRow || !value) {
        return;
      }
      this.commentsDialogData = {
        questionId: item.id,
        questionName: item.name,
        userId: this.userId,
        groupName: this.chartData.items.find((group) => group.id.toString() === item.parentId)?.name ?? '',
      };

      this.isShowCommentsDialog = true;
    },
    isAnonymityThresholdReached(item: TempItem) {
      if ('commentsOptions' in item) {
        return item?.commentsOptions?.anonymityThresholdReached;
      }
      return false;
    },
  },
});
