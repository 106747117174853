
import { defineComponent, PropType } from 'vue';
import { V1EntitiesAnalyticsTemplatesSettingsZone } from '@/services/api/tsxass';

export default defineComponent({
  name: 'HeatMapColorCellValue',

  props: {
    value: {
      type: Number,
      default: undefined,
    },
    zones: {
      type: Array as PropType<V1EntitiesAnalyticsTemplatesSettingsZone[]>,
      default: () => [],
    },
    height: {
      type: Number,
      default: 40,
    },
  },

  computed: {
    zone() {
      return this.zones.find((z) => z.value >= this.value) || this.zones[this.zones.length - 1];
    },
    valueIsEmpty(): boolean {
      return this.value === '' || this.value === null || this.value === undefined;
    },
    valueIsString(): boolean {
      return typeof this.value !== 'number';
    },
    buttonColor(): string | null {
      if (this.valueIsEmpty || this.valueIsString) {
        return null;
      }
      return this.zone.color;
    },
  },
});
