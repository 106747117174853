
import Vue, { PropType } from 'vue';
import { V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion, V1EntitiesAnalyticsTemplatesSettingsZone } from '@/services/api/tsxass';
import TTLoader from '@/components/ui/TTLoader.vue';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import QuestionGradeInfoCard from './QuestionGradeInfoCard.vue';

export default Vue.extend({
  name: 'GradeInfoDialog',

  components: {
    QuestionGradeInfoCard,
    TTLoader,
    NoFilteredDataStub,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array as PropType<V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    minRating: {
      type: Number,
      default: 1,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
    middleValueX: {
      type: Number,
      default: 7,
    },
    middleValueY: {
      type: Number,
      default: 7,
    },
    belowAnonymityThreshold: {
      type: Boolean,
      default: false,
    },
    zones: {
      type: Array as PropType<V1EntitiesAnalyticsTemplatesSettingsZone[]>,
      default: () => [],
    },
  },

  computed: {
    isShowDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
  },
});
