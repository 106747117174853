
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import NpsCompetenciesCommentsDialog from '@/components/analytics/widgets/common/NpsCompetenciesCommentsDialog.vue';
import BaseCompetenciesHeatMap from './BaseCompetenciesHeatMap.vue';

export default BaseCompetenciesHeatMap.extend({
  name: 'NpsHeatMap',

  components: {
    CompetenciesCommentsDialog: NpsCompetenciesCommentsDialog,
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const { data } = await tsxassApi.getV1AnalyticsFeedbackNpsQuestionsTemp(
          this.surveyId,
          this.userId || undefined,
        );
        this.chartData = {
          groups: data.groups || [],
          items: data.items || [],
          settings: data.settings || {
            minValue: -100,
            maxValue: 100,
            displayBenches: data.settings?.displayBenches,
          },
          zones: data.analyticsTemplateSettings.zones,
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
